import am from "./am.json";
import ar from "./ar.json";
import bs from "./bs.json";
import cs from "./cs.json";
import en from "./en.json";
import es from "./es.json";
import et from "./et.json";
import fil from "./fil.json";
import fr from "./fr.json";
import ha from "./ha.json";
import hi from "./hi.json";
import hr from "./hr.json";
import hy from "./hy.json";
import id from "./id.json";
import it from "./it.json";
import lt from "./lt.json";
import pl from "./pl.json";
import pt from "./pt.json";
import ro from "./ro.json";
import ru from "./ru.json";
import sl from "./sl.json";
import tr from "./tr.json";
import uk from "./uk.json";
import vi from "./vi.json";
import zh from "./zh.json";

export {
  am,
  ar,
  bs,
  cs,
  en,
  es,
  et,
  fil,
  fr,
  ha,
  hi,
  hr,
  hy,
  id,
  it,
  lt,
  pl,
  pt,
  ro,
  ru,
  sl,
  tr,
  uk,
  vi,
  zh
};
