//initializing rasa chat bot
//implementation link: https://github.com/botfront/rasa-webchat#in-a-script-tag
const initializeBot = (
  config,
  userProfileResponse,
  doInitBot,
  supportedLanguages,
  functionToGetCurrentJwt,
  i18n
) => {
  const localStorageKeyForRasa = "chat_session";
  const timeInMsToHideReceivedMessageDuringTypingIndicator = 1000;
  let lxpLanguageCode = userProfileResponse.language;

  // the chatbot is currently available only in some languages:
  if (!supportedLanguages.includes(lxpLanguageCode)) {
    return;
  }

  // getting the other required parameters to customize the chatbot behavior:
  let lxpUserId = `${userProfileResponse.keycloak_ref.toString()}`; // NOTE: required to be unique
  let lxpUserName = userProfileResponse.name;

  // smart way to let the Rasa Webchat client-side implementation
  // start by already adapting a predefined, desired session ID that
  // is imposed to the server directly on socket.io connection request and
  // that automatically becomes the user ID internally used by Rasa:
  if (!doInitBot) {
    window.localStorage.setItem(
      localStorageKeyForRasa,
      JSON.stringify({
        params: {
          isChatOpen: false,
          isChatVisible: true
        },
        version: "1.0.2"
      })
    );
  }
  // Rasa Webchat implementation exploitation, from
  // https://github.com/botfront/rasa-webchat#in-a-script-tag:
  !(function() {
    let e = document.createElement("script"),
      t = document.head || document.getElementsByTagName("head")[0];
    (e.src =
      "https://files.lxp.academy.who.int/machine-learning/whoa-rasa-webchat-index-0-0-7.js"),
      (e.async = !0),
      (e.onload = () => {
        window.WebChat.default(
          {
            socketUrl: `${config.rasa.url}`,
            getCurrentJwt: functionToGetCurrentJwt,
            initPayload: "/must_orchestrate",
            customData: {
              aiTutorCourseId: null, // null means the AI tutor is disabled
              languageCode: lxpLanguageCode,
              userName: lxpUserName
            },
            showCloseButton: true,
            title: "Cognity",
            subtitle: i18n.t("chatbot.header.subtitle"),
            inputTextFieldHint: i18n.t("chatbot.input.placeholder"),
            profileAvatar: `https://files.lxp.academy.who.int/learning-experience-platform/chatbot/cognity-icon.svg`,
            openLauncherImage: `https://files.lxp.academy.who.int/learning-experience-platform/chatbot/cognity-icon.svg`,
            showMessageDate: true,
            displayTypingIndication: true,
            customMessageDelay: () =>
              timeInMsToHideReceivedMessageDuringTypingIndicator,
            onSocketEvent: {
              connect: () => {
                if (!doInitBot) {
                  window.localStorage.setItem(
                    localStorageKeyForRasa,
                    JSON.stringify({
                      session_id: lxpUserId
                    })
                  );
                }
              }
            }
          },
          null
        );
      }),
      t.insertBefore(e, t.firstChild);
  })();
};

export default initializeBot;
