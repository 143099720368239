import i18next from "i18next";
// The below package is deprecated, we need to move to the official https://github.com/panter/vue-i18next
// when we have a stable release https://github.com/i18next/i18next-vue/tags
import VueI18Next from "@panter/vue-i18next";
import Vue from "vue";
import {
  am,
  ar,
  bs,
  cs,
  en,
  es,
  et,
  fil,
  fr,
  hi,
  ha,
  hr,
  hy,
  id,
  it,
  lt,
  pl,
  pt,
  ro,
  ru,
  sl,
  tr,
  uk,
  vi,
  zh
} from "./locales/index";

Vue.use(VueI18Next);

// initialize i18next
// reference: https://github.com/panter/vue-i18next
i18next.init({
  fallbackLng: "en",
  whitelist: ["en", "ar", "es", "fr", "ru", "zh"],
  interpolation: {
    prefix: "{",
    suffix: "}"
  },
  resources: {
    en: {
      translation: en
    },
    ar: {
      translation: ar
    },
    es: {
      translation: es
    },
    fr: {
      translation: fr
    },
    ru: {
      translation: ru
    },
    zh: {
      translation: zh
    },
    // other languages
    am: {
      translation: am
    },
    bs: {
      translation: bs
    },
    cs: {
      translation: cs
    },
    et: {
      translation: et
    },
    ha: {
      translation: ha
    },
    hi: {
      translation: hi
    },
    hr: {
      translation: hr
    },
    hy: {
      translation: hy
    },
    id: {
      translation: id
    },
    it: {
      translation: it
    },
    lt: {
      translation: lt
    },
    pl: {
      translation: pl
    },
    pt: {
      translation: pt
    },
    ro: {
      translation: ro
    },
    sl: {
      translation: sl
    },
    fil: {
      translation: fil
    },
    tr: {
      translation: tr
    },
    vi: {
      translation: vi
    },
    uk: {
      translation: uk
    }
  }
});
export default new VueI18Next(i18next);
