import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import interceptorSetup from "./core/interceptor";
import * as Keycloak from "keycloak-js";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@/assets/css/styles.scss";
import "isomorphic-fetch";
import toast from "./core/mixins/toast";
import "./core/filter";
import i18next from "./i18next";
import isLayoutLoaded from "./core/mixins/isLayoutLoaded";
Vue.mixin(isLayoutLoaded);
// function to intercept the api call and check for maintenance page condition
interceptorSetup();
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.mixin(toast);
Vue.component("ValidationProvider", () =>
  import("vee-validate/dist/vee-validate.full.esm").then(
    m => m.ValidationProvider
  )
);
Vue.component("ValidationObserver", () =>
  import("vee-validate").then(m => m.ValidationObserver)
);

const keyCloakTokenParam = {
  interval: 10000, // in milliseconds - interval to check the validity of token
  nearByTime: 60 // in seconds - It is use to calculate if it will be expire in next 60 seconds
};

fetch("/config/all.json").then(response => {
  response.json().then(config => {
    Vue.prototype.$keycloak = new Keycloak(config.SSO);
    Vue.prototype.$keycloak
      .init({
        onLoad: "check-sso",
        pkceMethod: "S256",
        checkLoginIframe: true,
        scope: "offline_access"
      })
      .then(authenticated => {
        const app = new Vue({
          router,
          store,
          i18n: i18next,
          render: h => h(App)
        }).$mount("#app");

        app.$store.commit("SET_CONFIG", config);
        require("./core/importWrapperComponents.js");
        if (!authenticated) {
          console.info("INIT - KC user not authenticated");
        } else {
          setInterval(() => {
            Vue.prototype.$keycloak
              .updateToken(keyCloakTokenParam.nearByTime)
              .then(refreshed => {
                console.info(
                  "Keycloak service",
                  refreshed
                    ? "Token is still valid and not refreshed"
                    : "Token is expired and has been refreshed"
                );
              });
          }, keyCloakTokenParam.interval);
        }
      })
      .catch(err => {
        console.error("ERROR Connecting to Keycloak service", err);
      });
  });
});
