export default {
  showFullPageLoader: false,
  error: "",
  courses: [],
  homeCourses: [],
  config: {},
  auth: {},
  authProfile: {},
  courseModules: [],
  selectedCourse: {},
  courseOverview: null,
  constants: null,
  new_user: null,
  welcome_modal_status: false,
  onboardingStatus: "",
  credentials: [],
  sessionCookie: [],
  languages: [],
  allEnrolledCourses: [],
  credential: {},
  language: "en",
  showCompleteProfile: false,
  showMaintenancePage: false,
  userProfile: {},
  serverTime: "",
  pageNotFound: false,
  doInitBot: true,
  chatbotLanguages: [
    "am",
    "ar",
    "bs",
    "cs",
    "en",
    "es",
    "et",
    "fr",
    "ha",
    "hi",
    "hr",
    "hy",
    "id",
    "it",
    "lt",
    "pl",
    "pt",
    "sl",
    "ro",
    "ru",
    "tl",
    "tr",
    "uk",
    "vi",
    "zh"
  ],

  // for layout
  expandSideNav: true,
  searchText: "",
  layout: "main",
  layoutUpdateTriggered: true,
  systemAlert: null,
  showSystemAlert: true,

  //for vue tour
  vueTourStatus: false,
  newVLEUser: null,

  //for discussion forums
  newForumsUser: null,

  //for header
  isHeaderFixed: false
};
