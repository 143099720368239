import axios from "axios";
import i18n from "../../../i18next";
import initializeBot from "../../../bot-rasa";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

function getURL(baseURL) {
  return {
    USER_PROFILE: `${baseURL}/profile`,
    UPDATE_PROFILE_PICTURE: `${baseURL}/profile/picture`
  };
}

export default {
  // GET
  getProfile({ commit, rootState, dispatch }) {
    const URL = getURL(rootState.config.baseURL).USER_PROFILE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    axios.defaults.headers.common["Accept-Language"] = rootState.language;

    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_USER_PROFILE", response.data.data);
        commit("SET_LANGUAGE", response.data.data.language);
        dispatch("intiliazeChatBot", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },

  // PATCH
  updateProfile({ commit, rootState, dispatch }, params) {
    const URL = getURL(rootState.config.baseURL).USER_PROFILE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    axios.defaults.headers.common["Accept-Language"] = rootState.language;
    return axios
      .patch(URL, params, headers)
      .then(response => {
        let data = response.data.data;
        commit("SET_USER_PROFILE", data);
        if (data.language !== rootState.language) {
          localStorage.setItem("user-language", data.language);
          commit("SET_LANGUAGE", data.language);
          commit("SET_DOINIT_BOT", false);
          dispatch("getProfile");
          dispatch("getAllConstants");
        }
        dispatch("intiliazeChatBot", data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },

  // PUT
  updateProfilePicture({ commit, rootState }, params) {
    return new Promise((resolve, reject) => {
      const URL = getURL(rootState.config.baseURL).UPDATE_PROFILE_PICTURE;
      const headers = getAuthHeader(this._vm.$keycloak.token);
      axios
        .put(URL, params, headers)
        .then(response => {
          commit("SET_PROFILE_PIC", {
            profile_image_url: response.data.profile_image_url,
            rootState: rootState
          });
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // DELETE
  deleteProfilePicture({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).UPDATE_PROFILE_PICTURE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .delete(URL, headers)
      .then(response => {
        commit("SET_PROFILE_PIC", {
          profile_image_url: null,
          rootState: rootState
        });
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },

  intiliazeChatBot({ commit, rootState }, profile) {
    if (
      !sessionStorage.getItem("name") ||
      sessionStorage.getItem("name") !== profile.name
    ) {
      sessionStorage.setItem("name", profile.name);
      commit("SET_DOINIT_BOT", false);
    }
    initializeBot(
      rootState.config,
      rootState.settings.profile,
      rootState.doInitBot,
      rootState.chatbotLanguages,
      () => this._vm.$keycloak.token,
      i18n
    );
  }
};
