var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`app-${_vm.language}`, _vm.getClassBasedOnRoute],attrs:{"id":"app","dir":_vm.language === 'ar' ? 'rtl' : 'ltr'},on:{"click":function($event){return _vm.onPageClick($event)}}},[_c('ToastWrapper'),(_vm.showMaintenancePage)?_c('div',{staticClass:"h-100"},[_c('MaintenancePage')],1):_c('div',{staticClass:"h-100"},[(_vm.isRouterView)?_c('div',{staticClass:"h-100 app__router-view-wrapper"},[_c(_vm.layout,{tag:"component"},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"layout":_vm.layout},on:{"update:layout":function($event){_vm.layout=$event}}})],1)],1):_c('div',{staticClass:"h-100"},[_c('ErrorPage')],1)]),(_vm.vueTourStatus)?_c('div',{staticClass:"ul-tour__backdrop",attrs:{"id":"ul-tour__backdrop"}}):_vm._e(),_c('div',{staticClass:"app__full-page-loader-wrapper",class:{
      'app__full-page-loader-wrapper--active': _vm.getShowFullPageLoader === true
    }},[_c('div',{staticClass:"app__full-page-loader-container"},[_c('Loader')],1)]),_c('div',{class:[
      'app__full-page-loader-wrapper',
      {
        'app__full-page-loader-wrapper--active': !_vm.isPageLoaded
      }
    ]},[_c('div',{staticClass:"app__full-page-loader-container"},[_c('LxpLoader')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }