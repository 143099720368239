import Vue from "vue";

export default {
  SET_SHOW_FULL_PAGE_LOADER(state, flag) {
    state.showFullPageLoader = flag;
  },
  SET_USER_STATUS(state, status) {
    state.new_user = status;
  },
  SET_WELCOME_MODAL_STATUS(state, status) {
    state.welcome_modal_status = status;
  },
  SET_ONBOARDING_PENDING_STATUS(state, status) {
    state.onboardingStatus = status;
  },
  SET_CONSTANTS(state, constants) {
    state.constants = constants;
  },
  GET_ALL_HOME_PAGE_COURSES(state, courses) {
    state.homeCourses = courses;
  },
  GET_COURSE_OVERVIEW(state, overview) {
    state.courseOverview = overview;
  },

  SET_SELECTED_COURSE(state, course) {
    state.selectedCourse = course;
  },
  UPDATE_SELECTED_COURSE_ENROLLMENT_STATUS(state, status) {
    Vue.set(state.selectedCourse, "is_enrolled", status);
  },
  SET_SESSION_COOKIE(state, cookies) {
    state.sessionCookie = cookies;
  },
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_AUTH(state, auth) {
    state.auth = auth;
  },
  SET_CURRENT_COURSE_MODULES(state, courses) {
    state.courseModules = courses;
  },
  SET_USER_AUTH_PROFILE(state, authProfile) {
    state.authProfile = authProfile;
  },
  GET_ALL_CREDENTIALS(state, credentials) {
    state.credentials = credentials;
  },
  SET_ERRORS(state, error) {
    state.error = error;
  },
  SET_ALL_ENROLLED_COURSES(state, courses) {
    state.allEnrolledCourses = courses;
  },
  SET_CREDENTIAL(state, credential) {
    state.credential = credential;
  },
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
  SET_DOINIT_BOT(state, doInitBot) {
    state.doInitBot = doInitBot;
  },
  SET_ALL_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_SHOW_COMPLETE_PROFILE(state, completeProfile) {
    state.showCompleteProfile = completeProfile;
  },
  SET_SHOW_MAINTENANCE_FLAG(state, flag) {
    state.showMaintenancePage = flag;
  },
  SET_KEYCLOAK_USER_PROFILE(state, profile) {
    state.userProfile = profile;
  },
  SET_SERVER_TIME(state, serverTime) {
    state.serverTime = serverTime;
  },
  SET_PAGE_NOT_FOUND_FLAG(state, flag) {
    state.pageNotFound = flag;
  },

  // for layout
  TOGGLE_SIDE_NAV(state) {
    state.expandSideNav = !state.expandSideNav;
  },
  SET_LAYOUT(state, layout) {
    state.layout = layout;
  },
  SET_LAYOUT_UPDATE_TRIGGERED(state, layoutUpdateTriggered) {
    state.layoutUpdateTriggered = layoutUpdateTriggered;
  },
  SET_SYSTEM_ALERT(state, alert) {
    state.systemAlert = alert;
  },
  SET_SYSTEM_ALERT_STATUS(state, status) {
    state.showSystemAlert = status;
  },

  // for vue tour
  SET_VUE_TOUR_STATUS(state, status) {
    state.vueTourStatus = status;
  },
  SET_VLE_USER_STATUS(state, status) {
    state.newVLEUser = status;
  },

  //for discussion forums
  SET_FORUMS_USER_STATUS(state, status) {
    state.newForumsUser = status;
  },

  //for header
  SET_HEADER_FIXED(state, status) {
    state.isHeaderFixed = status;
  }
};
